// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-beaconx-en-js": () => import("./../../../src/pages/beaconx-en.js" /* webpackChunkName: "component---src-pages-beaconx-en-js" */),
  "component---src-pages-beaconx-js": () => import("./../../../src/pages/beaconx.js" /* webpackChunkName: "component---src-pages-beaconx-js" */),
  "component---src-pages-clientx-en-js": () => import("./../../../src/pages/clientx-en.js" /* webpackChunkName: "component---src-pages-clientx-en-js" */),
  "component---src-pages-clientx-js": () => import("./../../../src/pages/clientx.js" /* webpackChunkName: "component---src-pages-clientx-js" */),
  "component---src-pages-demo-en-js": () => import("./../../../src/pages/demo-en.js" /* webpackChunkName: "component---src-pages-demo-en-js" */),
  "component---src-pages-demo-js": () => import("./../../../src/pages/demo.js" /* webpackChunkName: "component---src-pages-demo-js" */),
  "component---src-pages-digitaltwin-en-js": () => import("./../../../src/pages/digitaltwin-en.js" /* webpackChunkName: "component---src-pages-digitaltwin-en-js" */),
  "component---src-pages-digitaltwin-js": () => import("./../../../src/pages/digitaltwin.js" /* webpackChunkName: "component---src-pages-digitaltwin-js" */),
  "component---src-pages-dispatchx-en-js": () => import("./../../../src/pages/dispatchx-en.js" /* webpackChunkName: "component---src-pages-dispatchx-en-js" */),
  "component---src-pages-dispatchx-js": () => import("./../../../src/pages/dispatchx.js" /* webpackChunkName: "component---src-pages-dispatchx-js" */),
  "component---src-pages-index-en-js": () => import("./../../../src/pages/index-en.js" /* webpackChunkName: "component---src-pages-index-en-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-optimization-en-js": () => import("./../../../src/pages/optimization-en.js" /* webpackChunkName: "component---src-pages-optimization-en-js" */),
  "component---src-pages-optimization-js": () => import("./../../../src/pages/optimization.js" /* webpackChunkName: "component---src-pages-optimization-js" */),
  "component---src-pages-prices-en-js": () => import("./../../../src/pages/prices-en.js" /* webpackChunkName: "component---src-pages-prices-en-js" */),
  "component---src-pages-prices-js": () => import("./../../../src/pages/prices.js" /* webpackChunkName: "component---src-pages-prices-js" */)
}

